<template>
  <div class='auth-wrapper auth-v1'>
    <Message />
    <div class='auth-inner'>
      <v-card class='auth-card'>
        <!-- logo -->
        <v-card-title class='d-flex align-center justify-center py-0 mb-3'>
          <router-link class='d-flex align-center' to='/'>
            <v-layout align-center column>
              <img :src="require('@/assets/images/logos/logo.png')" alt='logo'
                   contain
                   max-height='28' max-width='28'
                   width='85'/>

              <h3 class='font-weight-semibold'>
                Sustainability Monitoring Platform
              </h3>
            </v-layout>
          </router-link>
        </v-card-title>
        <v-card-text class='d-flex align-center mt-2'>
          <v-divider></v-divider>
          <span class='mx-5'>&#10523; &#10524;</span>
          <v-divider></v-divider>
        </v-card-text>

        <!-- title -->
        <v-card-text class='pt-0'>
          <p class='text-xl font-weight-semibold text--primary mb-2'>
            Welcome to Us! 👋🏻
          </p>
          <p class='mb-2'>
            Please sign-in to your account and start the adventure
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form autocomplete='off' onSubmit='return false;'>
            <v-text-field v-model='email'  autocomplete='on' class='mb-3' hide-details
                          label='Email'
                          outlined placeholder='john@example.com'></v-text-field>

            <v-text-field v-model='password'
                          :append-icon='isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline'
                          :type="isPasswordVisible ? 'text' : 'password'"
                          autocomplete='on'
                          hide-details label='Password'
                          outlined
                          placeholder='············'
                          @click:append='isPasswordVisible = !isPasswordVisible'></v-text-field>

            <div class='d-flex align-center justify-space-between flex-wrap'>
              <v-spacer />
              <!--              <v-checkbox-->
              <!--                label="Remember Me"-->
              <!--                hide-details-->
              <!--                class="me-3 mt-1"-->
              <!--              >-->
              <!--              </v-checkbox>-->

              <!-- forgot link -->
              <!--              <a-->
              <!--                href='javascript:void(0)'-->
              <!--                class='mt-1'-->
              <!--              >-->
              <!--                Forgot Password?-->
              <!--              </a>-->
              <div class='pt-2'>
                <v-checkbox dense hide-details label='Remember me'></v-checkbox>
              </div>
            </div>

            <v-btn :loading='loading' block class='mt-6' color='primary' type='submit'
                   @click='login'>
              Login
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <!--        <v-card-text class='d-flex align-center justify-center flex-wrap mt-2'>-->
        <!--          <span class='me-2'>-->
        <!--            New on our platform?-->
        <!--          </span>-->
        <!--          <router-link :to="{name:'pages-register'}">-->
        <!--            Create an account-->
        <!--          </router-link>-->
        <!--        </v-card-text>-->

        <!-- divider -->

        <v-card-text class='d-flex align-center mt-2'>
          <v-divider></v-divider>
          <span class='mx-5'>&#10523; &#10524;</span>
          <v-divider></v-divider>
        </v-card-text>

        <!-- social links -->
        <!--        <v-card-actions class="d-flex justify-center">-->
        <!--          <v-btn-->
        <!--            v-for="link in socialLink"-->
        <!--            :key="link.icon"-->
        <!--            icon-->
        <!--            class="ms-1"-->
        <!--          >-->
        <!--            <v-icon :color="$vuetify.theme.dark ? link.colorInDark : link.color">-->
        <!--              {{ link.icon }}-->
        <!--            </v-icon>-->
        <!--          </v-btn>-->
        <!--        </v-card-actions>-->
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
         class='auth-mask-bg' height='173'
         style='opacity: 0.5'>

    <!-- tree -->
    <img class='auth-tree' height='185' src='@/assets/images/misc/tree.png' width='247' />

    <!-- tree  -->
    <img class='auth-tree-3' height='289' src='@/assets/images/misc/tree-3.png' width='377' />
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiFacebook,
  mdiGithub,
  mdiGoogle,
  mdiTwitter,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import { authLogin, checkToken } from '@/api/auth-api'
import Message from '@/components/Message.vue'
import { SYSADMIN } from '@/constants/role'

export default {
  components: { Message },
  data() {
    return {
      loading: false,
      urlPattern: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/
    }
  },
  setup() {
    const isPasswordVisible = ref(false)
    const email = ref('')
    const password = ref('')
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      isPasswordVisible,
      email,
      password,
      socialLink,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  mounted() {
    this.checkAuth()
  },
  methods: {
    async checkAuth() {
      try {
        let { data } = await checkToken()
        if (data.role == SYSADMIN) {
          location.href = `${location.origin}/admin`
        } else {
          const urlParams = (new URL(document.location)).searchParams;
          const redirectUrl = urlParams.get('redirect')
          if (redirectUrl && this.urlPattern.test(redirectUrl)) {
            location.href = redirectUrl
          } else {
            location.href = `${location.origin}/projects`
            localStorage.setItem('cacheRoute', '/projects')
          }
        }
      } catch (e) {
        console.warn('error when check auth: ', e)
      }
    },
    async login() {
      try {
        this.loading = true
        await authLogin({ email: this.email, password: this.password })
        await this.checkAuth()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang='scss'>
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
